<template>
    <div>

        <!-- <div class="d-flex text-white justify-content-end align-items-center px-2 pt-2  h4" style="gap:10px">
            Filter Data <i class="bx bxs-filter-alt" style="color: #FFAD32;"></i>
        </div> -->
 
        <h2 class="text-center text-white  mt-3">Mongrela Associated Shelter</h2>
        <hr style="border-color: #FFAD32;" />

        <div v-for="item in shelter" class="px-2 d-flex flex-column" style="gap:10px">
            <router-link :to="`/shelter/profile/${item.shelter_id}`" class="d-flex align-items-center px-2 py-1" style="gap:10px 20px;border: 2px solid #FFAD32;border-radius: 10px;">
                <div>
                    <img :src="item.shelter_picture"
                        class="rounded-circle" style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0">{{item.shelter_name}}</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .25em 0 !important;font-weight: normal;gap:5px">
                        <i class="bx bxs-map" style="font-size: 18px;"></i> {{ item.shelter_location }}    
                    </h3>
                </div>
                <!-- <div>
                    <h3 class="text-white h6 mb-0 text-right" style="">4.5 <i class="bx bxs-star" style="color:#FFAD32"></i></h3>
                </div> -->
            </router-link>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        shelter(){
            return store.state.shelter.SHELTER_LIST
        }
    },
    mounted(){
        store.dispatch('shelter/GetShelterList')
    },
    data(){
        return {
        }
    }
}

</script>